<div class="masthead-language-container">
  @if (!isMobile) {
    <ul class="p-l-0" role="presentation">
      <li ngbDropdown role="presentation" class="nav-item nav-li nav-li-login nav-item-dropdown"
        [ngClass]="{'nav-item-menu': language.isOpen()}" #language="ngbDropdown" [autoClose]="dropDownAutoClose">
        <a class="nav-link text-info focus-visibility" id="dropdownBasic4" href="javascript:void(0)"
          (click)="closeOtherDropdowns(language)" role="button" ngbDropdownToggle>
          {{ selectedLanguage.cultureShortName}}
          @if (!language.isOpen()) {
            <i class="float-end aon-icon aon-menu-collapse m-l-5" aria-hidden="true"></i>
          }
          @if (language.isOpen()) {
            <i class="float-end aon-icon aon-angle-up m-l-5" aria-hidden="true"></i>
          }
        </a>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic4" class="language-dropdown">
          @if (filteredallowedLanguage!=null) {
          <div class="language-dropdown-menu">
            <ul class="nav-dropdown-list">
              @for (item of filteredallowedLanguage; track item) {
              <li>
                <a (click)="SelectLanguage(item?.cultureCode, item?.cultureShortName)" ngbDropdownItem
                   [ngClass]="{'active-language': selectedLanguage.cultureShortName === item.cultureShortName}"
                   href="javascript:void(0)">
                  {{ item.cultureShortName }}
                </a>
              </li>
              }
            </ul>
          </div>
          }
        </div>
      </li>
    </ul>
  } @else {
    <div class="row">
      <div class="col-12">
        <label class="language-dropdown-wrap">
          <select class="dropdown" (change)=ChangeLanguage($event)>
            @if (filteredallowedLanguage!=null) {
            @for (item of filteredallowedLanguage; track item) {
            <option [attr.aria-label]="item.cultureShortName + ' ' + resourceStrings['Aon.Common.Selected']"
                    [attr.selected]="selectedLanguage.cultureShortName === item.cultureShortName? true: null"
                    [value]="item.cultureShortName">
              {{ item.cultureShortName }}
            </option>
            }
            }
          </select>
        </label>
      </div>
    </div>
  }

</div>
