<div class="express-login">
  <div class="modal-header">
    <h3 class="modal-title">{{resourceStrings['Aon.Login.ContinueToCheckout']}}</h3>
    <button type="button" class="close" title="" [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']"
      (click)="onClose()" *ngIf="!hideCloseButton">
      <span aria-hidden="true"><i class="fal fa-times" aria-hidden="true"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="m-b-20">
      {{resourceStrings['Aon.Login.GuestLoginEnterEmailToReceiveOTP']}}
    </div>
    <div>
      <form [formGroup]="loginForm">
        <div class="form-group">
          <ng-container *ngIf="curExpressLoginStep == localExpressLoginSteps.ProvideEmailAddress">
            <label for="username"
              [ngClass]="{ 'error': f.username.invalid && (f.username.dirty || f.username.touched) }">{{resourceStrings['Aon.Account.Email']}}</label>
            <div *ngIf="f.username.invalid && (f.username.dirty || f.username.touched)" class="alert contextual error">
              <div *ngIf="f.username.errors.required" role="alert">
                <i class="fas fa-exclamation-triangle"
                  aria-hidden="true"></i>{{resourceStrings['Aon.Account.EmailIsRequired']}}
              </div>
              <div *ngIf="!f.username.errors.required">
                <i class="fas fa-exclamation-triangle"
                  aria-hidden="true"></i>{{resourceStrings['Aon.Account.InvalidEmail']}}
              </div>
            </div>
            <input type="text" formControlName="username" id="username" class="m-b-8"
              [ngClass]="{ 'error': f.username.invalid && (f.username.dirty || f.username.touched) }" />
          </ng-container>

          <ng-container *ngIf="curExpressLoginStep == localExpressLoginSteps.GenerateOTP">
            <div>
              <label>{{resourceStrings['Aon.Account.Email']}}<i class="edit-email fal fa-edit m-l-10 focus-visibility"
                  role="button" tabindex="0" (keyup.enter)="editEmail()" (click)="editEmail()"
                  [attr.aria-label]="resourceStrings['Aon.Common.Edit']"></i></label>
              <span>{{f.username.value}}</span>
            </div>
          </ng-container>
        </div>

        <div class="form-group m-b-20" *ngIf="curExpressLoginStep == localExpressLoginSteps.GenerateOTP">
          <label for="password"
            [ngClass]="{ 'error': f.password.invalid && (f.password.dirty || f.password.touched) }">{{resourceStrings['Aon.Login.OneTimePassword']}}</label>
          <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)" class="alert contextual error">
            <div *ngIf="f.password.errors.required" role="alert">
              <i class="fas fa-exclamation-triangle"
                aria-hidden="true"></i>{{resourceStrings['Aon.Login.PasswordIsRequired']}}
            </div>
          </div>
          <aon-input-password formControlName="password" [id]="'password'"
            [ngClass]="{ 'error': f.password.invalid && (f.password.dirty || f.password.touched) }"
            [aria-label]="resourceStrings['Aon.Login.PasswordVisibilityControl']"></aon-input-password>
          <div class="d-flex align-items-center m-t-8 resend-code">
            <a href="javascript:void(0);" (click)="getOTP()" role="button" class="m-r-8 btn"
              [ngClass]="isResendCodeButtonDisabled? 'disabled':''">{{resourceStrings['Aon.Login.ResendCode']}}</a><span>{{countDownTimerForDisplay}}</span>
          </div>
        </div>
        <ng-container *ngIf="curExpressLoginStep == localExpressLoginSteps.GenerateOTP">
          <div tabindex="0" (click)="acceptPrivacyPolicy()" class="checkbox-wrapper" *ngIf="showPrivacyPolicySection">
            <input type="checkbox" [disabled]="!isPrivacyPolicyLinkClicked" [checked]="isPrivacyPolicyAccepted"
              [attr.aria-label]="resourceStrings['Aon.Login.PrivacyPolicyReadClaim']" />
            <label class="privacy-policy-checkbox secondary">
              <span class="check-toggle" [ngClass]="{'error':(!isPrivacyPolicyAccepted)}"></span>
              <span class="label content">{{resourceStrings['Aon.Login.PrivacyPolicyReadClaimBeforeLink']}}<a
                  href="{{privacyPolicyUrl}}" (click)="readPrivacyPolicy()" rel="noopener norefferrer"
                  target="_blank">{{resourceStrings['Aon.Registration.PrivacyPolicy']}}</a>{{resourceStrings['Aon.Login.PrivacyPolicyReadClaimAfterLink']}}<a
                  href="{{privacyPolicyUrl}}" (click)="readPrivacyPolicy()" rel="noopener norefferrer"
                  target="_blank">{{resourceStrings['Aon.Registration.PrivacyPolicy']}}</a></span>
            </label>
          </div>
          <div
            *ngIf="showMarketingPreferenceSection && employeeServiceCommsPreferences?.length > 0 && uiConfig.uiConfiguration.enableMarketingConsent"
            (click)="isMarketingSelectionSel=!isMarketingSelectionSel" tabindex="0" class="checkbox-wrapper">
            <input type="checkbox" [checked]="isMarketingSelectionSel"
              [attr.aria-label]="resourceStrings['Aon.Login.MarketingSelection']" />
            <label class="marketing-checkbox secondary">
              <span class="check-toggle" [ngClass]="{'error':(!isMarketingSelectionSel)}"></span>
              <span class="label content"
                [innerHTML]="resourceStrings['Aon.Login.MarketingSelection'] | safeHtml"></span>
            </label>
          </div>
        </ng-container>
        <div *ngIf="submitted && error">
          <div *ngIf="error" class="alert contextual error mt-3 mb-2" role="alert"><i
              class="fas fa-exclamation-triangle" aria-hidden="true"></i>{{error}}</div>
        </div>
      </form>
    </div>
    <div class="row m-b-40">
      <div class="col-md-6" *ngIf="curExpressLoginStep == localExpressLoginSteps.ProvideEmailAddress">
        <button class="login-submit spinner login-generate-password"
          [disabled]="f.username.pristine || (f.username.invalid && (f.username.dirty || f.username.touched))"
          (click)='getOTP()'>
          {{resourceStrings['Aon.Login.GeneratePassword']}}
          <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
        </button>
      </div>
      <div class="col-md-6" *ngIf="curExpressLoginStep == localExpressLoginSteps.GenerateOTP">
        <button class="login-submit spinner login-verify-email" [disabled]="loading || !isPrivacyPolicyAccepted"
          (click)='onSubmit()'>
          {{resourceStrings['Aon.Login.VerifyEmail']}}
          <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p class="sm" id="not-registered">{{resourceStrings['Aon.Login.HaveAccount']}} <a id="go-to-login"
            href="javascript:void(0);" (click)="goToLogin()"
            aria-labelledby="registered-go-to-login">{{resourceStrings['Aon.Login.LoginText']}}</a></p>
      </div>
      <div class="col-md-12">
        <p class="sm" id="need-help">{{resourceStrings['Aon.Account.NeedHelp']}} <a id="contact-help"
            [routerLink]="[baseUrl+'/home/help']" (click)="linkClick()"
            aria-labelledby="need-help">{{resourceStrings['Aon.Login.ContactHelpDesk']}}</a></p>
      </div>
    </div>

  </div>
</div>