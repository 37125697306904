<ng-container *ngIf="!hideCTAButton">
  <button class="call-to-action button-style anaval-{{name}}"
    *ngIf="callToAction && !callToAction.displayStyle && (isExternal || (!isExternal && appLinkConfigured))" [ngClass]="cssClass"
    (click)="ctaFunc(callToAction.additionalParameter, $event)" [fragment]="fragment" [routerLink]="">
    {{ctaDisplayText}}
    <span *ngIf="loading" class="spinner-border spinner-border-sm m-l-4"></span>
  </button>
  <button class="call-to-action button-style anaval-{{name}}"
    *ngIf="callToAction && !callToAction.displayStyle && !isExternal && !appLinkConfigured" [ngClass]="cssClass"
    (click)="ctaFunc(callToAction.additionalParameter, $event)" [fragment]="fragment"
    [routerLink]="checkSavedQuotesBeforeLaunchingQuote ? null : ctaLink">
    {{ctaDisplayText}}
    <span *ngIf="loading" class="spinner-border spinner-border-sm m-l-4"></span>
  </button>
  <a class="call-to-action link-style anaval-{{name}}" href="javascript:void(0);" tabindex="0"
    *ngIf="callToAction && callToAction.displayStyle && (isExternal || (!isExternal && appLinkConfigured))" [ngClass]="cssClass"
    (click)="ctaFunc(callToAction.additionalParameter, $event)"
    (keydown.enter)="ctaFunc(callToAction.additionalParameter, $event)" [fragment]="fragment"
    [routerLink]="">{{ctaDisplayText}}</a>
  <a class="call-to-action link-style anaval-{{name}}" *ngIf="callToAction && callToAction.displayStyle && !isExternal && !appLinkConfigured"
    [ngClass]="cssClass" (click)="ctaFunc(callToAction.additionalParameter, $event)" [fragment]="fragment"
    [routerLink]="checkSavedQuotesBeforeLaunchingQuote ? null : ctaLink">{{ctaDisplayText}}</a>
</ng-container>
