import { Component, ElementRef, Inject, OnInit, OnDestroy, Injector, Renderer2 } from '@angular/core';
import { BootstrapRequestContext } from 'tbs-typings';
import { HelperService } from 'src/app/shared/services/helper.service';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';
import { JwtHelper } from '../guards/jwt.helper';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { Subscription, takeUntil } from 'rxjs';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { ChildComponentOptions } from 'src/app/shared/models/childComponentOptions.model';
import { CxoneParamsModel } from 'src/app/shared/models/cxone.model';
declare const icPatronChat: any;
declare const cxone: any;

@Component({
  selector: 'app-nic-chat',
  templateUrl: './nic-chat.component.html'
})
export class NicChatComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  // icPatronChat = icPatronChat;
  niceSrc: string;
  niceServerHost: string;
  niceBusNo: string;
  nicePoc: string;
  preferredName: string = '';
  lastName: string = '';
  employeeId: string = '';
  employeeNumberId: string = '';
  culture: string;
  regionCode: string;
  tenantId: string;
  isExecutive: boolean = false;
  isAvailable: boolean = false;
  resetNiceSub: Subscription;
  userSubjectSub: Subscription;
  checkWorkTimeSub: Subscription;
  enableCxoneNiceWebChat: boolean;
  cxoneNiceInstanceNo: string;
  cxoneGuideTemplateGuid: string;
  cxoneNiceLoader: string;
  cxoneScriptLoaded: boolean = false;
  cxoneInitCompleted: boolean = false;
  constructor(
    public helperService: HelperService,
    private globalObjectsService: GlobalObjectsService,
    private authenticationService: AuthenticationService,
    private injector: Injector,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext) {
    super(injector, new ChildComponentOptions(true, false, true));
    this.niceSrc = this.bootstrapRequestContext.niceSrc;
    this.niceServerHost = this.bootstrapRequestContext.niceServerHost;
    this.niceBusNo = this.bootstrapRequestContext.niceBusNo;
    this.nicePoc = this.bootstrapRequestContext.nicePoc;
    this.tenantId = this.bootstrapRequestContext.nicTenantId;
    this.regionCode = this.bootstrapRequestContext.nicRegionCode ?? '';
    this.culture = this.bootstrapRequestContext.defaultCulture;
    this.enableCxoneNiceWebChat = this.bootstrapRequestContext.enableCxoneNiceWebChat;
    this.cxoneNiceInstanceNo = this.bootstrapRequestContext.cxoneNiceInstanceNo;
    this.cxoneGuideTemplateGuid = this.bootstrapRequestContext.cxoneGuideTemplateGuid;
    this.cxoneNiceLoader = this.bootstrapRequestContext.cxoneNiceLoader;
    this.cxoneScriptLoaded = false;
    this.cxoneInitCompleted = false;
  }

  ngOnInit(): void {
    this.globalObjectsService.resetNiceAfterLogout.next(false);
    if (this.helperService.isLoggedOn()) {
      this.setNiceForAuthUser();
    }
    else {
      if (this.enableCxoneNiceWebChat) {
        this.initCxoneNiceChat();
      }
      else {
        this.initChat();
      }
      this.resetNiceAfterLogin();
    }
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => this.getResouceStringsAsObject(res));
    this.resetNiceSub = this.globalObjectsService.resetNiceAfterLogout
      .subscribe(rn => {
        if (rn === true) {
          let chatel = document.getElementsByClassName('ie-div-position-customer-chat');
          if (chatel && chatel.length > 0) {
            chatel[0].remove();
          }
          if (this.helperService.AllowAnonymousAccess()) {
            let paramsValue = [
              this.tenantId,
              '',
              '',
              '',
              '',
              this.culture,
              this.regionCode,
              this.isExecutive.toString()
            ];

            if (this.enableCxoneNiceWebChat) {
              this.initCxoneNiceChat();
            }
            else {
              this.initChat(paramsValue);
            }
          }
        }
      }
      );
  }

  ngOnDestroy(): void {
    this.resetNiceSub.unsubscribe();
    if (this.userSubjectSub) {
      this.userSubjectSub.unsubscribe();
    }

    if (this.checkWorkTimeSub) {
      this.checkWorkTimeSub.unsubscribe();
    }
  }

  private setNiceForAuthUser() {
    this.globalObjectsService.compactEmployeeInformation
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(emp => {
        this.preferredName = emp?.preferredName;
        this.lastName = emp?.lastName;
        this.employeeId = emp?.employeeID;
        this.employeeNumberId = emp?.employeeNumberID;
        this.isExecutive = emp?.isExecutive;
        this.culture = JSON.parse(localStorage.getItem('SelectedLanguage')).cultureCode;
        let jwtHelper = new JwtHelper();
        let user = JSON.parse(sessionStorage.getItem('currentUser'));
        if (user?.id_token) {
          let parsedToken = jwtHelper.decodeToken(user.id_token);
          this.regionCode = parsedToken.RegionalAffliationCode;
          this.tenantId = parsedToken.TenantID;
          let chatel = document.getElementsByClassName('ie-div-position-customer-chat');
          if ((!chatel || chatel.length == 0) && (this.employeeId && this.employeeNumberId)) {
            if (this.enableCxoneNiceWebChat) {
              this.initCxoneNiceChat();
            }
            else {
              this.initChat();
            }
          }
        }
      });
  }

  private resetNiceAfterLogin() {
    if (this.helperService.AllowAnonymousAccess()) {
      this.userSubjectSub = this.authenticationService.currentUserSubject
        .subscribe(() => {
          if (this.helperService.isLoggedOn()) {
            let chatel = document.getElementsByClassName('ie-div-position-customer-chat');
            if (chatel && chatel.length > 0) {
              chatel[0].remove();
            }
            this.setNiceForAuthUser();
          }
        });
    }
  }

  private initChat(params: string[] = null): void {
    this.embedNiceScript();
    this.initNiceChatInstance(params);
  }

  private initCxoneNiceChat(): void {
    let paramsValue = new CxoneParamsModel(
      this.cxoneNiceInstanceNo,
      this.cxoneGuideTemplateGuid,
      this.tenantId,
      this.preferredName,
      this.lastName,
      this.employeeId,
      this.employeeNumberId,
      this.culture,
      this.regionCode,
      this.isExecutive.toString()
    );

    if (!this.cxoneScriptLoaded) {
      this.embedCxoneNiceScript();
      this.cxoneScriptLoaded = true;
    }
    this.initCxoneNiceChatInstance(paramsValue);
  }

  private embedNiceScript(): void {
    let script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = this.niceSrc;
    this.renderer.appendChild(this.elementRef.nativeElement, script);
  }

  private initNiceChatInstance(params: string[] = null): void {
    if (typeof (icPatronChat) !== "undefined") {

      let paramsValue = [
        this.tenantId,
        this.preferredName,
        this.lastName,
        this.employeeId,
        this.employeeNumberId,
        this.culture,
        this.regionCode,
        this.isExecutive.toString()
      ];

      if (params) {
        paramsValue = params;
      }

      let initParams = {
        serverHost: this.niceServerHost,
        bus_no: this.niceBusNo,
        poc: this.nicePoc,
        params: paramsValue
      };

      icPatronChat.init(initParams);
    } else {
      setTimeout(() => {
        this.initNiceChatInstance(params);
      }, 500);
    }
  }

  private cxoneNiceScript(): string {
    return `
      (function (i, s, o, r, g, v, a, m) {
		  g = v ? g + '?v=' + v : g; i['CXoneDfo'] = r;
		  i[r] = i[r] || function () {(i[r].q = i[r].q || []).push(arguments)}; i[r].l = +new Date(); i[r].u = g;
		  a = s.createElement(o); m = s.getElementsByTagName(o)[0]; a.async = true;
		  a.src = g + '?' + Math.round(Date.now() / 1000 / 3600); m.parentNode.insertBefore(a, m);
		})(window, document, 'script', 'cxone', '__CXONENICELOADER__');
    `;
  }

  private embedCxoneNiceScript(): void {
    let script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = this.cxoneNiceScript().replace('__CXONENICELOADER__', this.cxoneNiceLoader);
    this.renderer.appendChild(this.elementRef.nativeElement, script);
  }

  private initCxoneNiceChatInstance(params: CxoneParamsModel): void {
    if (params == null) {
      return;
    }

    if (typeof (cxone) !== "undefined") {

      if (!this.cxoneInitCompleted) {
        cxone('init', params.cxoneNiceInstanceNo);
        cxone('guide', 'init', params.cxoneGuideTemplateGuid);
        cxone('chat', 'setCustomCss', "[data-selector=\"ACTIVITY_BAR\"] {display: none !important}");

        this.cxoneInitCompleted = true;
      }
      
      if (params.tenantId != "") {
        cxone('chat', 'setCaseCustomField', 'tenantid', params.tenantId);
      }
      if (params.preferredName != "") {
        cxone('chat', 'setCaseCustomField', 'firstname', params.preferredName);
      }
      if (params.lastName != "") {
        cxone('chat', 'setCaseCustomField', 'lastname', params.lastName);
      }
      if (params.employeeId != "") {
        cxone('chat', 'setCaseCustomField', 'employeeid', params.employeeId);
      }
      if (params.employeeNumberId != "") {
        cxone('chat', 'setCaseCustomField', 'employeenumberid', params.employeeNumberId);
      }
      if (params.culture != "") {
        cxone('chat', 'setCaseCustomField', 'language', params.culture);
      }
      if (params.regionCode != "") {
        cxone('chat', 'setCaseCustomField', 'region', params.regionCode);
      }
      if (params.isExecutive != "") {
        cxone('chat', 'setCaseCustomField', 'isexecutive', params.isExecutive);
      }
      if (params.preferredName != "") {
        cxone('chat', 'setCustomerName', params.preferredName);
      }

    } else {
      setTimeout(() => {
        this.initCxoneNiceChatInstance(params);
      }, 500);
    }
  }
}
