import { Optional } from "./core-typing.model";

export interface Relationship {
  minDOB?: string,
  maxDOB?: string,
  recordID: string,
  name: string,
  description: string,
  relationshipType_RecordID: number,
  relationshipType_Name: string,
  employeeRelationshipType_RecordID: number,
  employeeRelationshipType_Name: string,
  sSNrequiredAsOfMonths: number,
  nationalIDTyperequired: boolean,
  nationalIDValidationKey: string,
  displayConsentRequired: boolean,
  consentAge_Years: number,
  consentAge_Months: number,
  includeMelded: boolean,
  showGivenName: boolean,
  showMiddleName: boolean,
  showSurName: boolean,
  showLegalName: boolean,
  showDOB: boolean,
  showIsSmoker: boolean,
  showCitizenship: boolean,
  showCountryofbirth: boolean,
  showIsStudent: boolean,
  showNationalID: boolean,
  showResidesWithEmployee: boolean,
  showAlternativePhoneNumber: boolean,
  showAlternativeEmail: boolean,
  showTaxEligible: boolean,
  showDateOfMarriage: boolean,
  showMaritalStatus: boolean,
  showGender: boolean,
  showAlternateAddress: boolean,
  showHomePhone: boolean,
  showHomeEmail: boolean,
  showHomeAddress: boolean,
  showTitle: boolean,
  showCPF: boolean,
  showCity: boolean,
  showStateOrProvince: boolean,
  showMotherName: boolean,
  showCountry: boolean,
  showMiscDate1: boolean,
  showMiscDate2: boolean,
  showMiscDate3: boolean,
  showMiscText1: boolean,
  showMiscText2: boolean,
  showMiscText3: boolean,
  showMiscText4: boolean,
  showMiscText5: boolean,
  canEditGivenName: boolean,
  canEditMiddleName: boolean,
  canEditSurName: boolean,
  canEditLegalName: boolean,
  canEditDOB: boolean,
  canEditIsSmoker: boolean,
  canEditCitizenship: boolean,
  canEditCountryofbirth: boolean,
  canEditIsFullTimeStudent: boolean,
  canEditNationalID: boolean,
  canEditResidesWithEmployee: boolean,
  canEditAlternativePhoneNumber: boolean,
  canEditAlternativeEmail: boolean,
  canEditTaxEligible: boolean,
  canEditDateOfMarriage: boolean,
  canEditGender: boolean,
  canEditMaritalStatus: boolean,
  canEditAlternateAddress: boolean,
  canEditHomePhone: boolean,
  canEditHomeEmail: boolean,
  canEditHomeAddress: boolean,
  canEditTitle: boolean,
  requiredGivenName: boolean,
  requiredMiddleName: boolean,
  requiredSurName: boolean,
  requiredLegalName: boolean,
  requiredDOB: boolean,
  requiredIsSmoker: boolean,
  requiredCitizenship: boolean,
  requiredCountryofbirth: boolean,
  requiredIsStudent: boolean,
  requiredNationalID: boolean,
  requiredResidesWithEmployee: boolean,
  requiredAlternativePhoneNumber: boolean,
  requiredAlternativeEmail: boolean,
  requiredTaxEligible: boolean,
  requiredDateOfMarriage: boolean,
  requiredMaritalStatus: boolean,
  requiredGender: boolean,
  requiredAlternateAddress: boolean,
  requiredHomePhone: boolean,
  requiredHomeEmail: boolean,
  requiredHomeAddress: boolean,
  requiredTitle: boolean,
  requiredCPF: boolean,
  requiredCity: boolean,
  requiredStateOrProvince: boolean,
  requiredMotherName: boolean,
  requiredCountry: boolean,
  requiredMiscDate1: boolean,
  requiredMiscDate2: boolean,
  requiredMiscDate3: boolean,
  requiredMiscText1: boolean,
  requiredMiscText2: boolean,
  requiredMiscText3: boolean,
  requiredMiscText4: boolean,
  requiredMiscText5: boolean,
  canEditWorkEmail: boolean,
  showWorkEmail: boolean,
  requiredIsDisabled: boolean,
  requiredIsEmployed: boolean,
  requiredIsMedicareEligible: boolean,
  requiredDateOfDeath: boolean,
  requiredDateOfDivorce: boolean,
  showIsDisabled: boolean,
  showIsEmployed: boolean,
  showIsMedicareEligible: boolean,
  showDateOfDeath: boolean,
  showDateOfDivorce: boolean,
  canEditIsDisabled: boolean,
  canEditIsEmployed: boolean,
  canEditIsMedicareEligible: boolean,
  canEditDateOfDeath: boolean,
  canEditDateOfDivorce: boolean,
  canEditCPF: boolean,
  canEditMotherName: boolean,
  canEditMiscDate1: boolean,
  canEditMiscDate2: boolean,
  canEditMiscDate3: boolean,
  canEditMiscText1: boolean,
  canEditMiscText2: boolean,
  canEditMiscText3: boolean,
  canEditMiscText4: boolean,
  canEditMiscText5: boolean,
  showPostalCode: boolean,
  showAddressLine1: boolean,
  showAddressLine2: boolean,
  showAddressLine3: boolean,
  requiredPostalCode: boolean,
  requiredAddressLine1: boolean,
  requiredAddressLine2: boolean,
  requiredAddressLine3: boolean,
  beneficiaryTrusteeFloorDate: string,
  showNationalIDType: boolean,
  requiredNationalIDType: boolean,
  canEditNationalIDType: boolean
}

export class RelationshipFilter {
  EmployeeBenefitID: number | string | null;
  Line_RecordID: string | null;
  BenefitPlan_RecordID: string | null;
  Mode_RecordID: RelationshipFilterMode;
  PrimaryDependant_RecordID?: string | null = null;
  IsPartial: boolean;
  ReasonRelationshipID?: string = "";
  LimitParticipantsByType: boolean;
  constructor() {
    this.EmployeeBenefitID = null;
    this.Line_RecordID = null;
    this.BenefitPlan_RecordID = null;
    this.Mode_RecordID = RelationshipFilterMode.Dependant;
    this.IsPartial = false;
    this.LimitParticipantsByType = false;
  }

  public HasDifference(filter: RelationshipFilter) {
    if (!filter) return true;

    let result = false;
    const propertyNames = Object.getOwnPropertyNames(this);
    for (let i = 0; i < propertyNames.length; i++) {
      const propertyName = propertyNames[i];
      if (!this.IsExactSame(filter, propertyName)) {
        result = true;
        break;
      }
    }

    return result;
  }

  private IsExactSame(filter: RelationshipFilter, propertyName: string) {
    if (filter && Object.prototype.hasOwnProperty.call(this, propertyName) && Object.prototype.hasOwnProperty.call(filter, propertyName)) {
      const data1 = this[propertyName];
      const data2 = filter[propertyName];
      return (data1 == null && data2 == null) || data1 === data2;
    }

    return false;
  }
}

export class RelationshipFilterMode {
  static Dependant = 1;
  static Beneficiary = 2;
}

export type OptionalRelationshipFilter = Optional<RelationshipFilter, keyof RelationshipFilter>;
