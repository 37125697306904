import { Component, Input, Output, EventEmitter, OnInit, ElementRef, Inject, OnDestroy, Injector } from '@angular/core';
import { LoginService } from '../services/login.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { LanguageSelectionData } from '../models/languageSelectionData.model';
import { SharedSableFlowComponent } from '../sable/shared-sable-flow/shared-sable-flow.component';
import { GlobalObjectsService } from '../services/global-objects.service';
import { HelperService } from '../services/helper.service';
import { AnalyticsService } from '../services/analytics.service';
import { JackalEngine } from '@aon/jackal';
import { ProductService } from '../../modules/product/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from '../models/product.model';
import { PurchaseStatus } from '../models/PurchaseStatus.model';
import { SessionStorageKey } from '../models/constants';
import { BootstrapRequestContext } from 'tbs-typings';
import { QrcodePopupComponent } from './qrcode-popup/qrcode-popup.component';
import { ContentPopupComponent } from './content-popup/content-popup.component';
import { ProfileService } from 'src/app/modules/profile/profile.service';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
import { takeUntil } from 'rxjs';
import { SavedQuoteInfoComponent } from 'src/app/modules/home/saved-quote-info/saved-quote-info.component';
import { CTAModel } from '../models/callToAction.model';

@Component({
  selector: 'app-aon-cta',
  templateUrl: './aon-cta.component.html'
})
export class AonCtaComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  baseUrl: string;
  @Input() fragment: string;
  @Input() callToAction: CTAModel.CallToActionFields;
  @Input() redirectUrl: string = "";
  @Input() sableFlowId: string = "";
  @Input() injectStyle: string = null;
  @Input() defaultStyle: string = null;
  @Input() needLogin: boolean = false;
  @Input() additionalContextData: any;
  @Input() ctaCaption: string;
  @Input() overrideDisplayText: boolean = false;
  @Output('ctaCustomFunc') ctaCustomFunc: EventEmitter<CTAModel.AdditionalParameter> = new EventEmitter();
  public name: string;
  ctaLink: string;
  jackalEngine = new JackalEngine();
  hideCTAButton: boolean = false;
  productId: any;
  currentProduct: Product;
  ctaDisplayText: string;
  loading = false;
  cssClass = "";
  isExternal: boolean = false;
  purchaseStatus: PurchaseStatus = null;
  apiBaseUrl: string;
  checkSavedQuotesBeforeLaunchingQuote: boolean;
  appLinkConfigured: boolean = false;
  constructor(
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext,
    private authenticationService: AuthenticationService,
    private loginService: LoginService,
    private globalObjectsService: GlobalObjectsService,
    private modalService: NgbModal,
    private productService: ProductService,
    public helperService: HelperService,
    public analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private router: Router,
    private profileService :ProfileService,
    private elRef: ElementRef,
    private injector: Injector
  ) {
    super(injector);
    this.baseUrl = this.helperService.getBaseUrl();
    this.apiBaseUrl = this.bootstrapRequestContext.apiBaseUrlNoVersion;
  }

  ngOnInit(): void {
    this.getCSSClass();
    this.name = this.helperService.getSafeClassName(this.callToAction.itemTitle);
    this.productService.productPurchaseStatus.subscribe(ps => {
      this.checkCTAButtonStatus(ps);
      this.evaluateCTADisplayText(ps);
      this.purchaseStatus = ps;
    });
    this.route.params.subscribe(params => {
      let id = params['id'];
      this.globalObjectsService.Products.subscribe(r => {
        if (this.productId != id) {
          this.productId = id;        
        }
        let products = r.filter(r => r.line_GUID == this.productId);
        if (products != null && products.length > 0) {
          this.currentProduct = products[0];
          if(this.callToAction.actionType == "QuoteAction"){
            this.checkSavedQuotesBeforeLaunchingQuote =  this.currentProduct.checkSavedQuotesBeforeLaunchingQuote;
          }
        }
      });
     
    });
  }



  getCSSClass() {
    if (this.injectStyle) {
      this.cssClass = this.injectStyle;
    }

    else if (this.callToAction.cssClass) {
      this.cssClass = this.callToAction.cssClass;
    }

    else if (this.defaultStyle) {
      this.cssClass = this.defaultStyle;
    }

    else {
      this.cssClass = 'prominent';
    }
  }

  checkCTAButtonStatus(purchaseStatus: PurchaseStatus) {
    if (purchaseStatus && this.callToAction.displayCondition) {
      if (this.jackalEngine.PassesConditionParameter(this.callToAction.displayCondition, { purchaseStatus: purchaseStatus })) {
      this.hideCTAButton = false;
      } else {
        this.hideCTAButton = true;
      }
    } else {
      this.hideCTAButton = false;
    }
  }

  evaluateCTADisplayText(purchaseStatus: PurchaseStatus) {


    // For 'Get A Quote' button, to display CTA information returned by EMS instead of that configured in CMS on priority
    let displayText = this.callToAction.displayText;
    if (this.callToAction && (this.callToAction.actionType == "QuoteAction" || this.overrideDisplayText) && this.ctaCaption != null && this.ctaCaption != "") {
      displayText = this.ctaCaption;
    }

    if (purchaseStatus) {
      let evaluatedJackalText = this.jackalEngine.EvaluateParameter(displayText, { isComplete: !purchaseStatus.requireFurtherAction });
      if (evaluatedJackalText != null) {
        this.ctaDisplayText = evaluatedJackalText;
      } else {
        this.ctaDisplayText = displayText;
      }
    }
    else {
      this.ctaDisplayText = displayText;
    }
  }

  ngOnChanges(): void {
    this.evaluateCTADisplayText(this.purchaseStatus);
    this.ctaLink = null;
    if (this.callToAction && (this.callToAction.actionType == "RedirectAction" || this.callToAction.actionType == "QuoteAction") && this.redirectUrl != null) {
      if (this.callToAction.actionType == "QuoteAction") {
        this.ctaLink = this.baseUrl + this.redirectUrl;
      } else if (this.callToAction.actionType == "RedirectAction") {
        const appLink = this.getApplink();
        if (appLink) {
          this.appLinkConfigured = true;
        } else {
          this.appLinkConfigured = false;
        }
        let linkUrlExternal = this.callToAction.linkUrl;
        if (this.helperService.isExternalUrl(linkUrlExternal) || linkUrlExternal.indexOf("{cmsfile}") == 0) {
          this.isExternal = true;
        }
        this.ctaLink = this.redirectUrl;
        this.assembleCTACampaign();
      }

      // callToAction.linkUrl (Url in CTA configuration) is on high priority, and will override the redirectUrl codeed in componenet
      // We have scenario that CTA Url will contain fragment '#', for example '/profile#dependentsandbeneficiaries'
      // Field 'fragment' of this component has already handled the part of fragment in Url
      // And '#' will be encoded by angular [routerLink] that will cause redirect issue.
      // So it needs to remove '#' from linkUrl
      if (this.callToAction.linkUrl && this.callToAction.linkUrl.trim() != "") {
        let linkUrl = this.callToAction.linkUrl;

        if (linkUrl.includes('#')) {
          let fragment = linkUrl.split('#')[1];
          this.fragment = this.fragment != fragment ? fragment : this.fragment;

          linkUrl = linkUrl.split('#')[0];
        }

        if (linkUrl.indexOf(this.baseUrl) == -1 && !this.isExternal) {
          this.ctaLink = this.baseUrl + linkUrl;
        }
        else {
          this.ctaLink = linkUrl;
          this.assembleCTACampaign();
        }
      }
    }

  }

  ctaFunc(param: string, event: any): void {

    if (this.ctaLink == '/client-content-page') {
      sessionStorage.setItem(SessionStorageKey.ClientContentPageCallToActions, window.btoa(unescape(encodeURIComponent(JSON.stringify(this.callToAction)))));
    }
    this.globalObjectsService.CTAs.next(this.callToAction);
    this.loading = true;
    if (this.callToAction.actionType == "CustomizedAction") {
      this.loading = false;
      event.preventDefault();
      setTimeout(() => {
        this.ctaCustomFunc.emit(JSON.parse(param));
      }, 100);
    }
    else if (this.callToAction.actionType == "QuoteAction") {
      //trigger the link     
  
      if (this.checkSavedQuotesBeforeLaunchingQuote) {
        this.profileService.checkEmployeeSavedQuotesExists(this.productId)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(response => {
            if (response) {
              const ngbModalOptions: NgbModalOptions = {
                backdrop: 'static',
                keyboard: false,
                ariaLabelledBy: 'leave-flow-alert-modal-title'
              };
              ngbModalOptions.container = '#app-modal-anchor';

              this.modalService.open(SavedQuoteInfoComponent, ngbModalOptions).result.then((result) => {
                this.loading = false;
                if (result) {
                  const redirectUrl = this.baseUrl + '/profile';
                  this.router.navigate([redirectUrl], { fragment: 'savedquote' });
                }
                else {
                  this.router.navigate([this.baseUrl + this.redirectUrl]);
                  return;
                }
              }, (reason) => {
              });
            }
            else {
              this.router.navigate([this.baseUrl + this.redirectUrl]);
              return;
            }
          }
          );
      }
      else {
        this.router.navigate([this.baseUrl + this.redirectUrl]);
        return;
      }
    }
    else {
      this.buildInFunc();
      this.loading = false;
    }

    if (this.callToAction.triggerCustomEvent) {
      this.analyticsService.trackCustomEvent({
        "event": "custom-cta-event",
        "cta-event-category": this.callToAction.eventCategory,
        "cta-event-action": this.callToAction.eventAction,
        "cta-event-label": this.callToAction.eventLabel
      });
    }
  }

  private buildInFunc(): void {
    if (this.callToAction.actionType == "RedirectAction") {
      this.triggerRedirection();
    }  
    if (this.callToAction.actionType == "SSOAction") {
      if (!this.authenticationService.isLogin) {
        this.loginOpen();
        return;
      }
      if (this.callToAction.additionalParameter !== null && this.authenticationService.isLogin) {
        this.triggerOsso(this.callToAction.additionalParameter.trim());
      }
      return;
    }
    if (this.callToAction.actionType == "AuthAction") {
      if (!this.authenticationService.isLogin) {
        this.loginOpen();
      }
      return;
    }
    if (this.callToAction.actionType == "SableFlowPopupAction") {
      this.sablePopup();
    }
    if (this.callToAction.actionType == "QRCodePopupAction") {
      this.qrCodePopup();
    }
    if (this.callToAction.actionType == "ContentPopupAction") {
      this.contentPopup();
    }
  }

  private loginOpen(): void {
    if (!this.authenticationService.isLogin) {
      let returnUrl = location.pathname;
      this.loginService.open(returnUrl, this.helperService.removeEmptySpace(this.currentProduct.name), true, () => {
        this.loginCallback();
      });
    }
  }

  private sablePopup() {
    const modalRef = this.modalService.open(SharedSableFlowComponent);
    modalRef.componentInstance.flowID = this.sableFlowId;
    modalRef.componentInstance.additionalContextData = this.additionalContextData;
    modalRef.result.then((data: any) => {
      this.sablePopupCallback();
    }, (reason) => {
      /*Leave empty or handle reject*/
      /*This is used to avoid the JS error when calling dismiss to close a modal*/
    });
  }
  private qrCodePopup() {

    const modalRef = this.modalService.open(QrcodePopupComponent);
    modalRef.componentInstance.value = this.callToAction.additionalParameter;
  }
  private contentPopup() {

    const modalRef = this.modalService.open(ContentPopupComponent);

    modalRef.componentInstance.contentItemPath = this.callToAction.additionalParameter;
  }

  private loginCallback(): void {
    if (this.callToAction.actionType == "SSOAction") {
      if (this.callToAction.additionalParameter !== null && this.authenticationService.isLogin) {
        this.triggerOsso(this.callToAction.additionalParameter.trim());
      }
    }
  }

  private sablePopupCallback(): void {
    this.globalObjectsService.reloadPurchaseSummary.next(true);
  }

  private triggerRedirection() {
    const appLink = this.getApplink();

    if (appLink) {
      this.ctaLink = appLink;
      this.helperService.openExteralLink(this.ctaLink);
    }

    if (appLink == "" && this.ctaLink && this.isExternal) {
      if (this.ctaLink.indexOf("{cmsfile}") == 0) {
        this.ctaLink = this.ctaLink.replace('{cmsfile}/', this.apiBaseUrl);
      }
      this.helperService.openExteralLink(this.ctaLink);
    }
  }

  private getApplink() : string {
    let appLink = "";
    if (sessionStorage.getItem(SessionStorageKey.IsFromMobileApp) == 'true') {
      if (this.helperService.isFromAndroidApp()) {
        if (this.callToAction.appLinksForAndroid) {
          appLink = this.callToAction.appLinksForAndroid + '&Applink';
        }
      } else if (this.callToAction.appLinksForIOS) {
        appLink = this.callToAction.appLinksForIOS + '&Applink';
      }
    }
    return appLink;
  }

  private triggerOsso(ssoID): void {
    let userAccessToken = JSON.parse(sessionStorage.getItem('currentUser'));
    let curLanguageCode: LanguageSelectionData = JSON.parse(localStorage.getItem('SelectedLanguage'));
    let currentCulture = curLanguageCode.cultureCode;
    let stateData = "";
    let tPRequestItem = [];
    this.authenticationService.osso(ssoID, userAccessToken.access_token, currentCulture, stateData, tPRequestItem, '', false, this.callToAction.appLinksForAndroid, this.callToAction.appLinksForIOS);
  }

  ngAfterViewInit(): void {
    if (this.callToAction.accessibleDescription && this.callToAction.accessibleDescription.trim() != '') {
      this.setAriaLabelForTheCTAInteractiveElements("a");
      this.setAriaLabelForTheCTAInteractiveElements("button");
    }
  }

  /**
   * Add Aria label for the CTA which has accessible descriptions
   */
  setAriaLabelForTheCTAInteractiveElements(tagName: string): void {
    let elements = this.elRef.nativeElement.querySelectorAll(tagName);
    if (elements.length > 0) {
      elements.forEach((element: any) => {
        element.setAttribute('aria-label', this.callToAction.accessibleDescription);
      })
    }
  }

  assembleCTACampaign() {

    if (this.callToAction.triggerUTMParameters) {
      this.appendUTMValue("utm_source");
      this.appendUTMValue("utm_medium");
      this.appendUTMValue("utm_campaign");
      this.appendUTMValue("utm_term");
      this.appendUTMValue("utm_content");
    }

    if (this.callToAction.transferTID) {
      let currentTID = this.analyticsService.getDataLayerValue("TID");
      if (currentTID) {
        this.ctaLink = this.helperService.appendUrlParameter(this.ctaLink, "external_TID", currentTID);
      }
    }
  }

  appendUTMValue(targetParameter: string) {
    let targetValue = this.callToAction[targetParameter];
    if (targetValue) {
      if (targetValue.startsWith('{{DataLayer:') && targetValue.endsWith('}}')) {
        const targetValueKey = targetValue.slice(12, targetValue.length - 2);
        targetValue = this.analyticsService.getDataLayerValue(targetValueKey);
      }
      if (targetValue) {
        this.ctaLink = this.helperService.appendUrlParameter(this.ctaLink, targetParameter, targetValue);
      }
    }
  }

  ngOnDestroy(): void {}
}
