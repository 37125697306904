import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LanguageSelectionComponent } from './core/language-selection/language-selection.component';
import { provideUserIdleConfig } from 'angular-user-idle';
import { MastheadComponent } from './core/masthead/masthead.component';
import { MastheadDelegateComponent } from './core/masthead/masthead-delegate/masthead-delegate.component';
import { MastheadDelegateBarComponent } from './core/masthead/masthead-delegate-bar/masthead-delegate-bar.component';
import { SelfServiceMastheadComponent } from './modules/self-service/self-service-masthead/self-service-masthead.component';
import { DelegateNoAccessAlertComponent } from './shared/delegate-no-access-alert/delegate-no-access-alert.component';
import { JwtInterceptor, ErrorInterceptor } from './core/interceptors';
import { FooterComponent } from './core/footer/footer.component';

import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { IdleTimeoutComponent } from './shared/idle-timeout/idle-timeout.component';
import { ExternalLinkPopupComponent } from './shared/external-link-popup/external-link-popup.component';
import { GlobalErrorHandlerService } from './shared/services/global-error-handler.service';
import { ResourceStringsService } from './shared/services/resource-strings.service';
import { ToastsContainerComponent } from './shared/toasts-container/toasts-container.component';
import { MastheadProductsComponent } from './core/masthead/masthead-products/masthead-products.component';
import { MastheadBenefitsComponent } from './core/masthead/masthead-benefits/masthead-benefits.component';
import { MastheadLogoComponent } from './core/masthead/masthead-logo/masthead-logo.component';
import { MastheadProfileComponent } from './core/masthead/masthead-profile/masthead-profile.component';
import { MastheadProfileDelegateComponent } from './core/masthead/masthead-profile-delegate/masthead-profile-delegate.component';
import { MastheadDelegateEmployeesComponent } from './core/masthead/masthead-delegate-employees/masthead-delegate-employees.component';
import { AuthenticationService } from './core/authentication/authentication.service';
import { SableFlowDataService } from '@aon/sable-flow';
import { AppFlowDataService } from './shared/sable/app-flow-data.service';
import { AppFormDataService } from './shared/sable/app-form-data.service';
import { AppViewDataService } from './shared/sable/app-view-data.service';
import { MastheadArticlesComponent } from './core/masthead/masthead-articles/masthead-articles.component';
import { SableFormDataService } from '@aon/sable-forms';
import { SableDataViewComponentFactory, SableDataViewDataService } from '@aon/sable-dataview';
import { AppDataViewComponentFactory } from './shared/sable/app-view.factory';
import { MastheadCartComponent } from './core/masthead/masthead-cart/masthead-cart.component';
import { UiconfigrationService } from './core/services/uiconfigration.service';
import { concat, defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientContentPageComponent } from './modules/client/client-content-page/client-content-page.component';
import { AON_LOCALE, AonLocaleService } from '@aon/aon-angular-common';
import { MastheadProductsV2Component } from './core/masthead/masthead-products-v2/masthead-products-v2.component';
import { FooterV2Component } from './modules/category/footer-v2/footer-v2.component';
import { JwtHelper } from './core/guards/jwt.helper';
import { SsoRouterComponent } from './modules/sso-router/sso-router.component';
import { CallbackComponent } from './modules/callback/callback.component';
import { MetaDataService } from './shared/services/metadata.service';
import { CustomLoggedOffComponent } from './modules/client/custom-logged-off/custom-logged-off.component';


@NgModule({
  declarations: [
    AppComponent,
    LanguageSelectionComponent,
    FooterComponent,
    IdleTimeoutComponent,
    ExternalLinkPopupComponent,
    ToastsContainerComponent,
    MastheadComponent,
    MastheadDelegateComponent,
    MastheadDelegateBarComponent,
    MastheadProductsComponent,
    MastheadBenefitsComponent,
    MastheadProfileComponent,
    MastheadProfileDelegateComponent,
    MastheadDelegateEmployeesComponent,
    SelfServiceMastheadComponent,
    DelegateNoAccessAlertComponent,
    MastheadLogoComponent,
    MastheadCartComponent,
    ClientContentPageComponent,
    MastheadProductsV2Component,
    FooterV2Component,
    MastheadArticlesComponent,
    SsoRouterComponent,
    CallbackComponent,
    CustomLoggedOffComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    //FormsModule,
    //ReactiveFormsModule,
    NgxUiLoaderModule,
    CoreModule,
    //DemoModule,
    SharedModule.forRoot()
    
  ],
  providers: [
    { provide: 'BootstrapRequestContext', useFactory: getBootstrapRequestContext },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AuthenticationService, UiconfigrationService, HttpClient, AonLocaleService, AON_LOCALE, ResourceStringsService, MetaDataService],
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: SableFlowDataService, useClass: AppFlowDataService },
    { provide: SableFormDataService, useClass: AppFormDataService },
    { provide: SableDataViewDataService, useClass: AppViewDataService },
    { provide: SableDataViewComponentFactory, useClass: AppDataViewComponentFactory },
    { provide: AON_LOCALE, useValue: {} },
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 30 
    // and `ping` is 120 (2 minutes).
    provideUserIdleConfig({ idle: 570, timeout: 30, ping: 120 }),
    DatePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getBootstrapRequestContext() {
  if (typeof window !== 'undefined' && typeof window['BootstrapRequestContext'] === 'function') {
    return window['BootstrapRequestContext']();
  } else {
    return null;
  }
}

function initializeApp(
  provider: AuthenticationService,
  uiconfigrationService: UiconfigrationService,
  httpClient: HttpClient,
  localeService: AonLocaleService,
  aonLocale: unknown,
  resService: ResourceStringsService,
  metadataService: MetaDataService
): () => Observable<unknown> {
  //only request on first page access.
  return () => {
    //No guest, so it is from ISSO
    const observables = [];
    if (sessionStorage.getItem('currentUser') != null) {
      observables.push(uiconfigrationService.getUIConfigurationFromService());
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      provider.afterLoginCall(user);
      let jwtHelper = new JwtHelper();
      let parsedToken = jwtHelper.decodeToken(user.id_token);
      if (parsedToken.RolesForUIStrings && JSON.parse(parsedToken.RolesForUIStrings).length > 0) {
        const currentCultureCode = setDefaultLanguage()['cultureCode'];
        observables.push(resService.preloadAllResourceStringsByRolesPerSite(currentCultureCode));
        observables.push(defer(() => provider.UpdateAonLocaleByRole(parsedToken)));
      } else {
        observables.push(defer(() => setAonLocale(cultureCode, resService)));
      }
    } else if (sessionStorage.getItem('currentGuest') == null) {
      observables.push(provider.guestToken(true));
      //Get UI config after guest token back
      observables.push(uiconfigrationService.getUIConfigurationFromService());
    } else {
      observables.push(uiconfigrationService.getUIConfigurationFromService());
      observables.push(provider.checkTokenValid());
    }

    const cultureCode = setDefaultLanguage()['cultureCode'];

    resService.preloadCommonStrings();
    // Loading the currency metadata
    observables.push(metadataService.currencies);

    if (sessionStorage.getItem('currentUser') == null) {
      resService.preloadAllResourceStringsByRolesPerSite(cultureCode).subscribe();
      observables.push(defer(() => setAonLocale(cultureCode, resService)));
    }
    observables.push(defer(() => localeService.set(cultureCode)));
    return concat(...observables);
  };

  function setDefaultLanguage(): { cultureCode: string; cultureShortName: string } {
    let selectedLanguage;
    let preferredCulture = sessionStorage.getItem('preferredCulture');
    if (preferredCulture != null && preferredCulture != '' && JSON.parse(preferredCulture) != '') {
      selectedLanguage = { cultureCode: JSON.parse(preferredCulture), cultureShortName: '' };
    } else if (localStorage.getItem('SelectedLanguage') == null) {
      preferredCulture = getBootstrapRequestContext().defaultCulture;
      selectedLanguage = { cultureCode: preferredCulture, cultureShortName: '' };
    }
    if (selectedLanguage) {
      if (selectedLanguage.cultureCode) {
        localStorage.setItem('SelectedLanguage', JSON.stringify(selectedLanguage));
      } else {
        console.log("app module, setDefaultLanguage, selectedLanguage.cultureCode is null.")
      }
    } else {
      selectedLanguage = JSON.parse(localStorage.getItem('SelectedLanguage'));
    }
    return selectedLanguage;
  }

  function setAonLocale(cultureCode: string, resService: ResourceStringsService): Observable<void> {
    const cdnStaticFileShareBaseURL = getBootstrapRequestContext().cdnStaticFileShareBaseURL;
    //const apiBaseUrl = getBootstrapRequestContext().apiBaseUrl;
    //const requestUrl = apiBaseUrl + cultureCode + '/uiresourcestrings';
    //return httpClient.post<{ key: string; value: string }[]>(requestUrl, stringKeys).pipe(
    return resService.getAonLocaleStringsAsObservable().pipe(
      map((data) => {
        const resourceString = {};
        data.forEach((res) => {
          if (res.value != res.key) {
            resourceString[res.key] = res.value;
          }
        });
        let uiConfigGuest = uiconfigrationService.getUIConfiguration();

        const newAonLocale = {
          calendar: {
            patterns: {
              d: resourceString['AON_LOCALE.calendar.patterns.d']
            },
            placeholders: {
              year: resourceString['AON_LOCALE.calendar.placeholders.year'],
              month: resourceString['AON_LOCALE.calendar.placeholders.month'],
              day: resourceString['AON_LOCALE.calendar.placeholders.day']
            },
            messages: {
              today: resourceString['AON_LOCALE.calendar.messages.today'],
              nextButtonTitle: resourceString['AON_LOCALE.calendar.messages.nextButtonTitle'],
              prevButtonTitle: resourceString['AON_LOCALE.calendar.messages.prevButtonTitle'],
              toggle: resourceString['AON_LOCALE.calendar.messages.toggle']
            }
          },
          currency: {
            code: uiConfigGuest?.uiConfiguration?.customCurrencyCode,
            symbol: uiConfigGuest?.uiConfiguration?.customCurrencySymbol
          },
          cdn: {
            staticFileShareBaseURL: cdnStaticFileShareBaseURL
          }
        };
        Object.assign(aonLocale, newAonLocale);
      })
    );
  }
}
