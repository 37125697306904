import { Component, Inject, Injector, Input, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CommonService } from '../../core/services';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { NotificationService } from '../../core/services/notification.service';
import { LanguageSelectionData } from '../models/languageSelectionData.model';
import { JwtHelper } from '../../core/guards/jwt.helper';
import { User } from '../models';
import { HelperService } from '../services/helper.service';
import { BootstrapRequestContext } from 'tbs-typings';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { MFAService } from 'src/app/shared/services/mfa.service';
import { HomeService } from '../../modules/home/home.service';
import { take, takeUntil, takeWhile } from 'rxjs/operators';
import { AON_LOCALE } from '@aon/aon-angular-common';
import { UIConfigurationType } from '../models/uiconfigration.interface';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
import { ExpressLoginSteps, LocalStorageKey, OtpVerificationOption, RegularExpression } from '../models/constants';
import { BehaviorSubject, forkJoin, Subscription, timer } from 'rxjs';
import { SessionStorageKey } from 'src/app/shared/models/constants';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ProfileService } from '../../modules/profile/profile.service';
import { EmployeeServiceCommsPreference } from '../../modules/profile/models/employeeServiceCommsPreference.model';
import { GlobalObjectsService } from '../services/global-objects.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html'
})
export class LoginComponent extends TBSBaseComponent implements OnInit {
  @Input() isFromForgotPassword = false;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  darkModeEnabled: boolean;
  selectedLanguage: LanguageSelectionData;
  currentUser: User;
  baseUrl: string;
  enabledFirstTimeUser = true;
  uiConfig: any;
  siteType: string;
  showSsoErrorMsg = false;
  isLoginSuccess: boolean = false;
  resetSelectedLanguage: boolean = false;
  resetLanguageList: boolean = false;
  isExpressLogin: boolean = false;
  isMarketingSelectionSel: boolean | null = null;
  localExpressLoginSteps = ExpressLoginSteps;
  curExpressLoginStep: string;
  isPrivacyPolicyAccepted: boolean;
  timerSubscription: Subscription;
  startedVerifyCode = false;
  showResendCodeButton = false;
  isResendCodeButtonDisabled = true;
  expireTimeInSeconds = 60;
  hasCodeBeenSentSuccessfully: boolean = false;
  isAccountExists: boolean;
  showSucessStep: boolean;
  countDownTimerForDisplay = '';
  isPrivacyPolicyLinkClicked: boolean = false;
  mfaInProgress: boolean = false;
  loginImageStyle: string;
  loginImagePath: string = "";
  employeeServiceCommsPreferences: EmployeeServiceCommsPreference[];
  customLoginLinks: any;
  whiteListTagsForCustomLogIn: string[] = ['A'];
  showPrivacyPolicySection: boolean = false;
  isExpressRegistered: boolean = false;
  showMarketingPreferenceSection: boolean = false;
  privacyPolicyUrl: string;
  isModalLogin: boolean = false;
  isMonitor: boolean = false;
  isMobileView: boolean;
  screenWidgth: number;
  constructor(
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected authenticationService: AuthenticationService,
    protected commonService: CommonService,
    protected jwtHelper: JwtHelper,
    public helperService: HelperService,
    public globalObjectsService: GlobalObjectsService,
    protected homeService: HomeService,
    protected uiconfigrationService: UiconfigrationService,
    protected notificationService: NotificationService,
    protected mfaService: MFAService,
    protected profileService: ProfileService,
    protected ngxService: NgxUiLoaderService,
    @Inject('BootstrapRequestContext') protected bootstrapRequestContext: BootstrapRequestContext,
    @Inject(AON_LOCALE) protected AON_LOCALE,
    public injector: Injector
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Login.UserName',
      'Aon.Login.Password',
      'Aon.Login.UserNameIsRequired',
      'Aon.Login.PasswordIsRequired',
      'Aon.Login.EnterYourCode',
      'Aon.Login.EnterYourCodeTips',
      'Aon.Login.InvalidCode',
      'Aon.Login.Continue',
      'Aon.Login.VerificationCode',
      'Aon.Login.InvalidUserNameOrPwd',
      'Aon.Login.SendNewCode',
      'Aon.Login.CodeExpirationSuffix',
      'Aon.Login.LoginText',
      'Aon.Login.Forgotpassword',
      'Aon.Login.ForgotUserName',
      'Aon.Login.IsNewUser',
      'Aon.Account.NeedHelp',
      'Aon.Login.CreateAccount',
      'Aon.Login.ContactUs',
      'Aon.Login.LoginTitle',
      'Aon.Login.PasswordVisibilityControl',
      'Aon.Common.ChangePwdSuccessfully',
      'Aon.Common.ErrorTitle',
      'Aon.Common.ErrorIntro',
      'Aon.Login.OLBLoginTitle',
      'Aon.Common.BannerTitle.Success',
      'Aon.Common.BannerTitle.Error',
      'Aon.SSO.SsoLoginError',
      'Aon.SSO.SsoLoginErrorLoginToSite',
      'Aon.SSO.SsoLoginErrorEmployerMessage',
      'Aon.SSO.SsoLoginErrorVisitAgain',
      'Aon.Navigation.Login',
      'Aon.Login.LoginSuccessMessage',
      'Aon.Login.ExpressCheckout',
      'Aon.Login.ContinueToCheckout',
      'Aon.Login.GuestLoginInfo',
      'Aon.Login.GuestLoginEnterEmailToReceiveOTP',
      'Aon.Login.PrivacyPolicyReadClaim',
      'Aon.Login.PrivacyPolicyReadClaimBeforeLink',
      'Aon.Login.PrivacyPolicyReadClaimAfterLink',
      'Aon.Registration.PrivacyPolicy',
      'Aon.Login.MarketingSelection',
      'Aon.Account.Email',
      'Aon.Account.EmailIsRequired',
      'Aon.Account.InvalidEmail',
      'Aon.Login.GeneratePassword',
      'Aon.Login.VerifyEmail',
      'Aon.Login.OneTimePassword',
      'Aon.Login.ResendCode',
      'Aon.Login.InvalidCode',
      'Aon.Login.HaveAccount',
      'Aon.Login.CodeHasBeenSentSuccessfully',
      'Aon.Login.CodeExpirationSuffix',
      'Aon.Login.ContactHelpDesk',
      'Aon.Login.PasswordVisibilityControl',
      'Aon.Common.TooManyRequests',
      'Aon.Common.Edit',
      'System.Common.Error',
      'Aon.Login.LoginWithOTP',
      'Aon.Login.NoPassword',
      'Aon.BannerAlert.Close',
      'Aon.Registration.AccountInOtherTenant',
      'Aon.Common.NoAccountFunctionProvided'
    ]);

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      verificationcode: ['']
    });
    if (this.helperService.isExpressCheckoutEnabled()) {
      this.curExpressLoginStep = "";
    }
  }
  ngOnInit(currentReturnUrl = '') {
    this.commonService.loginInProgress = true;
    let uiConfigType = this.uiconfigrationService.getUIConfiguration();
    this.uiConfig = this.authenticationService.getUIConfig();
    this.showConfiguredLoginImage();
    this.darkModeEnabled = uiConfigType?.uiConfiguration?.enableDarkMode;

    if (this.route.snapshot.queryParams['hasssoerror'] == 'true') {
      this.showSsoErrorMsg = true;
    }

    if (!this.showSsoErrorMsg && this.uiConfig && this.uiConfig.uiConfiguration && this.uiConfig.uiConfiguration.enableExternalLoginPage) {
      let returnUrl =
        this.route.snapshot.queryParams['ReturnUrl'] ||
        this.route.snapshot.queryParams['returnUrl'] ||
        this.route.snapshot.queryParams['returnurl'];
      if (returnUrl && returnUrl.indexOf("profile#documents?tab=BENEFIT_STATEMENT&statement=") >= 0) {

        localStorage.setItem(LocalStorageKey.ProfileDocumentAccessDate, new Date().getTime().toString());
        localStorage.setItem(LocalStorageKey.ProfileDocument, returnUrl);
      }
      this.isMonitor = this.route.snapshot.queryParams['IsMonitor'] == 'true';
      if (!this.isMonitor) {
        document.location.href = this.uiConfig.uiConfiguration.externalLoginPath;
      }

    }

    if (!this.commonService.logoutInProgress && !this.helperService.AllowAnonymousAccess()) {
      this.authenticationService.logout().subscribe();
    }

    this.commonService.appInitInProgress = false;
    // logout the previous user
    if (this.authenticationService.isLogin) {
      this.authenticationService.logout().subscribe();
    }

    if (sessionStorage.getItem('AllowedLanguages') == null) {
      sessionStorage.setItem('AllowedLanguages', JSON.stringify(this.bootstrapRequestContext.languageSelection));

    }
    this.baseUrl = this.helperService.getBaseUrl();

    this.privacyPolicyUrl = this.helperService.getPrivacyPolicyUrl();

    this.siteType = this.helperService.siteType;
    if(this.bootstrapRequestContext.siteStyleSheetEnabled){
      this.loadStyleSheet();
    }
    if (this.uiConfig.uiConfiguration.enableCustomLoginLinks) {
      this.loadCustomLoginContents();
    }
    if (sessionStorage.getItem('AllowedLanguages') != null) {
      this.loadResourceStringAsObservable()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          this.getResouceStringsAsObject(res);
          this.logPageView();
        });
      this.commonService.logoutAndGuestTokenBack = new BehaviorSubject<boolean>(false);
      this.commonService.logoutAndGuestTokenBack.subscribe(b => {//Make sure after login the Aonidentity in API has been refreshed
        if (b) {
          this.commonService.resetLanguageList.next(true);

        }
      });
    } else {
      if (!this.commonService.logoutInProgress) {
        this.reloadAllowedLanguagesFromServer();
      }
      else {
        this.commonService.logoutAndGuestTokenBack.subscribe(b => {//Make sure after login the Aonidentity in API has been refreshed
          if (b) {
            this.reloadAllowedLanguagesFromServer();
          }
        });
      }
    }

    // get return url from route parameters or default to '/'
    if (currentReturnUrl == '') {
      this.returnUrl =
        this.route.snapshot.queryParams['ReturnUrl'] ||
        this.route.snapshot.queryParams['returnUrl'] ||
        this.route.snapshot.queryParams['returnurl'] ||
        this.baseUrl;
    } else {
      this.returnUrl = currentReturnUrl;
    }
    this.afterInit();
    if (!this.isModalLogin) {
      this.logPageView();
    }
  }

  showConfiguredLoginImage() {
    this.commonService.getLoginImage().subscribe(loginImage => {
      this.loginImagePath = loginImage;
    });
  }

  private reloadAllowedLanguagesFromServer() {
    this.commonService.getAllowedLanguages()
      .pipe(take(1))
      .subscribe((data) => {
        sessionStorage.setItem('AllowedLanguages', JSON.stringify(data));
        this.commonService.resetLanguageList.next(true);
        if (localStorage.getItem('SelectedLanguage') == null && data.currentLanguage.cultureCode) {
          localStorage.setItem('SelectedLanguage', JSON.stringify(data.currentLanguage)); // What about setDefaultLanguage?
        } else if (!data.currentLanguage.cultureCode) {
          console.log("language selection component,reloadAllowedLanguagesFromServer, cultureCode is null.")
        }

        this.loadResourceStringAsObservable()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(res => {
            this.getResouceStringsAsObject(res);
            this.logPageView();
          });
      });
  }

  afterInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('login');
  }

  afterSumbit(): void {
    // Don't delete,  this will be called in child class in order to close popup
  }

  get f() {
    return this.loginForm.controls;
  }

  isFieldValid(field: string) {
    return !this.loginForm.get(field).valid && this.loginForm.get(field).touched;
  }

  hasError(controlName: string, errorName: string) {
    return this.loginForm.controls[controlName].hasError(errorName);
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onSubmit() {
    if (this.uiConfig?.uiConfiguration?.loginByMitIDOnly) {
      let languageCode = this.helperService.getCurrentLanguageCode().cultureCode;
      window.location.href = '/SSO/LoginMitid?lang=' + languageCode;
    }
    this.showSsoErrorMsg = false;
    this.submitted = true;
    this.error = '';

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.validateAllFormFields(this.loginForm);
      return;
    }
    const curLanguageCode: LanguageSelectionData = JSON.parse(localStorage.getItem('SelectedLanguage'));

    this.loading = true;
    this.homeService.hideHomeBanners.next(true);
    const encryptPWD = this.helperService.encrypt(this.f.password.value);
    if (encryptPWD != false) {
      this.logCustomEvent("login");
      this.authenticationService.login(this.f.username.value, encryptPWD, curLanguageCode.cultureCode, this.isExpressLogin, this.isMarketingSelectionSel)
        .pipe(take(1))
        .subscribe({
          next: (data) => {
            this.logCustomEvent("login", true);
            if (data.success == undefined) {
              this.isLoginSuccess = true;
              this.afterSumbit();
              this.currentUser = data;
              const jwtHelper = new JwtHelper();
              const parsedToken = jwtHelper.decodeToken(this.currentUser.id_token);
              if (parsedToken.EnableMFA == 'True') {
                this.authenticationService.tempUser = this.currentUser;
                if (this.returnUrl == '/') {
                  this.mfaService.returnUrl = this.router.url;
                } else {
                  this.mfaService.returnUrl = this.returnUrl;
                }
                sessionStorage.setItem(SessionStorageKey.MfaTempUser, JSON.stringify(this.currentUser));
                this.mfaInProgress = true;
              } else {
                this.afterSubmitSuccess();
              }
            } else {
              if (data && data.errorCode && data.errorCode == "PasswordExpired") {
                sessionStorage.setItem(SessionStorageKey.PasswordExpiredUser, this.f.username.value);
                this.router.navigate([this.baseUrl + '/account/setnewpassword']);
              } else {
                this.error = data.message;
                this.loading = false;
              }
            }
          },
          error: (error) => {
            this.error = this.resourceStrings['Aon.Login.InvalidUserNameOrPwd'];
            this.loading = false;
          }
        });
    } else {
      this.error = this.resourceStrings['Aon.Login.InvalidUserNameOrPwd'];
      this.loading = false;
    }
  }

  public afterSubmitSuccess() {
    const jwtHelper = new JwtHelper();
    this.globalObjectsService.logoutProfile = false;
    if (sessionStorage.getItem(SessionStorageKey.MfaTempUser)) {
      this.currentUser = JSON.parse(sessionStorage.getItem(SessionStorageKey.MfaTempUser));
      this.authenticationService.tempUser = this.currentUser;
    }
    const parsedToken = jwtHelper.decodeToken(this.currentUser.id_token);

    sessionStorage.setItem('Id_CustomSettings', parsedToken.UserCustomSettings);
    if (parsedToken.IsExpressRegistered) {
      sessionStorage.setItem('isExpressLogin', parsedToken.IsExpressRegistered.toLowerCase());
    }

    const hasMismatchedParticipantsStr = parsedToken.HasMismatchedParticipants;
    if (hasMismatchedParticipantsStr == 'True') {
      let hasMismatchedParticipants = true;
      sessionStorage.setItem(SessionStorageKey.HasMismatchedParticipants, hasMismatchedParticipants.toString());
    }

    let uiConfigId: string = parsedToken.UiConfigID;
    this.authenticationService.setAuthenticatedUser(this.currentUser);
    this.uiconfigrationService.getUIConfigurationFromService(uiConfigId)
      .pipe(take(1))
      .subscribe(uiConfigData => {

        this.enabledFirstTimeUser = uiConfigData.uiConfiguration.enableFirstTimeUser;
        this.RoleToLanguageCheckAndSet(uiConfigData, parsedToken);
        this.authenticationService.afterLoginCall(this.currentUser);
        const customSettings = JSON.parse(parsedToken.UserCustomSettings);
        const isTempPassword = customSettings.IsTemporaryPassword;
        this.completeValidation(isTempPassword);
        if (this.resetSelectedLanguage) {
          this.helperService.resetStringCache();
        }
        this.authenticationService.UpdateAonLocaleByRole(parsedToken).subscribe();

        this.notificationService.sendMessage('');
        this.commonService.resetClientBrandStyle.next(true);
        this.commonService.resetOpenAccessDetails.next(true);
        this.AON_LOCALE.currency.code = uiConfigData?.uiConfiguration?.customCurrencyCode;
        this.AON_LOCALE.currency.symbol = uiConfigData?.uiConfiguration?.customCurrencySymbol;


        //login success, remove the following items to load it according employee
        if (!this.resetLanguageList) {
          sessionStorage.removeItem('AllowedLanguages');
        }
      });
    sessionStorage.removeItem(SessionStorageKey.MfaTempUser);
    this.loading = false;
    this.commonService.loginInProgress = false;
  }


  private RoleToLanguageCheckAndSet(uiConfigData: UIConfigurationType, parsedToken: any) {
    if (uiConfigData.uiConfiguration.roleToLanguageEnable) {
      let allowedCultures = JSON.parse(sessionStorage.getItem('AllowedLanguages'));
      if (localStorage.getItem('SelectedLanguage') && JSON.parse(localStorage.getItem('SelectedLanguage')).cultureCode != parsedToken.PreferredCulture) {
        //Reset localstorgage and selected language
        let selectedLanguage = allowedCultures.allowedLanguages.filter(al => al.cultureCode == parsedToken.PreferredCulture)[0];
        if (selectedLanguage.cultureCode) {
          localStorage.setItem('SelectedLanguage', JSON.stringify(selectedLanguage));
          this.resetSelectedLanguage = true;
        } else {
          console.log("language selection component,RoleToLanguageCheckAndSet, selectedLanguage.cultureCode is null.")
        }
      }
      let udpatedAllowedCultures = parsedToken.UpdatedAllowedCultures;
      if (udpatedAllowedCultures && udpatedAllowedCultures != "null" && udpatedAllowedCultures.length > 0 && allowedCultures.allowedLanguages.length != udpatedAllowedCultures.length) {
        //Update the language selecton list
        let newLanguages = allowedCultures.allowedLanguages.filter(al => udpatedAllowedCultures.indexOf(al.cultureCode) !== -1);
        allowedCultures.allowedLanguages = newLanguages;
        sessionStorage.setItem('AllowedLanguages', JSON.stringify(allowedCultures));
        this.resetLanguageList = true;
      }
    }
  }

  loadStyleSheet(): void {
    document.getElementById("sitestyleseet").innerHTML = '';
    if (!document.getElementById("sitestyleseet").innerHTML) {
      this.commonService.getStyleSheet()
        .pipe(take(1))
        .subscribe(data => {
          let style = document.createElement('style');
          style.type = 'text/css';
          style.innerHTML = data;
          document.getElementById("sitestyleseet").appendChild(style);
        });
    }

  }

  completeValidation(isTempPassword = false): void {
    this.loading = false;

    if (this.enabledFirstTimeUser && isTempPassword) {
      this.router.navigate([this.baseUrl + '/account/firsttimeuser']).then(
        () => {
          setTimeout(() => {
            this.afterNavigateEvent();
          });
        }
      );
    }
    else if (this.returnUrl.indexOf('#') > 0) {
      this.router.navigate([this.returnUrl.substring(0, this.returnUrl.indexOf('#'))], {
        fragment: this.returnUrl.substring(this.returnUrl.indexOf('#') + 1)
      }).then(
        () => {
          setTimeout(() => {
            this.afterNavigateEvent();
          });
        }
      );
    }
    else {
      this.router.navigateByUrl(this.returnUrl).then(
        () => {
          setTimeout(() => {
            this.afterNavigateEvent();
          });
          
        }
      );
    }


    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('login');

  }

  private afterNavigateEvent() {
    if (this.resetLanguageList) {
      this.commonService.resetLanguageList.next(true); //reset language list
    }
    if (this.resetSelectedLanguage) {
      this.commonService.resetSelectedLanguage.next(true);
    }
  }

  callExternalApi() {
    if (this.uiConfig && this.uiConfig.uiConfiguration && this.uiConfig.uiConfiguration.enableExternalLoginPage) {
      document.location.href = this.uiConfig.uiConfiguration.externalLoginPath;
    }
  }
  backToLogIn() {
    this.mfaInProgress = false;
    this.loading = false;
    this.loginForm.controls["username"].reset();
    this.loginForm.controls["password"].reset();
    this.loginForm.controls["username"].addValidators(Validators.required);
  }

  goToLogin(): void {
    this.isExpressLogin = false;
    this.curExpressLoginStep = "";
    this.loginForm.controls["username"].reset();
    this.loginForm.controls["password"].reset();
    this.loginForm.controls["username"].clearValidators();
    this.loginForm.controls["username"].addValidators(Validators.required);
  }

  goToExpressLogin(): void {
    this.isExpressLogin = true;
    this.curExpressLoginStep = this.localExpressLoginSteps.ProvideEmailAddress;
    this.loginForm.controls["username"].reset();
    this.loginForm.controls["password"].reset();
    this.loginForm.controls["username"].clearValidators();
    this.loginForm.controls["username"].addValidators([Validators.required, Validators.email, Validators.pattern(RegularExpression.Email)]);
  }

  isExpressCheckoutEnabled(): boolean {
    return this.helperService.isExpressCheckoutEnabled();
  }

  isNormalLogin(): boolean {
    return (this.helperService.isExpressCheckoutEnabled() == false || (this.helperService.isExpressCheckoutEnabled() && this.curExpressLoginStep == ""));
  }

  getOTP(): void {
    this.submitted = false;
    this.error = "";
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.hasCodeBeenSentSuccessfully = false;
    this.f.password.setValue("");

    forkJoin({
      sendCodeResult: this.authenticationService.sendCode(OtpVerificationOption.Email, this.f.username.value),
      marketingConsentResult: this.profileService.getEmployeeServiceCommsPreferences()
    })
      .subscribe(
        ({
          sendCodeResult,
          marketingConsentResult
        }) => {
          if (sendCodeResult) {
            this.showResendCodeButton = true;
            this.isAccountExists = sendCodeResult.isAccountExists;
            this.isExpressRegistered = sendCodeResult.isExpressRegistered;
            this.isPrivacyPolicyAccepted = sendCodeResult.isPrivacyPolicyAccepted;
            this.showPrivacyPolicySection = !sendCodeResult.isPrivacyPolicyAccepted;
            this.showMarketingPreferenceSection = !sendCodeResult.isMarketingPreferenceCompleted;
            if (sendCodeResult.didSend) {
              this.curExpressLoginStep = this.localExpressLoginSteps.GenerateOTP;
            } else {
              this.submitted = true;
              if (sendCodeResult.accountInOtherTenant) {
                this.error = this.resourceStrings['Aon.Registration.AccountInOtherTenant'];
              } else {
                this.error = this.resourceStrings["System.Common.Error"];
              }
            }
          }
          if (marketingConsentResult?.length > 0) {
            this.isMarketingSelectionSel = !marketingConsentResult[0].isOptedOutOfComms;
            this.employeeServiceCommsPreferences = marketingConsentResult;
          }
        });
    this.startCounter(this.expireTimeInSeconds);
  }

  editEmail(): void {
    this.submitted = false;
    this.error = "";
    this.isPrivacyPolicyLinkClicked = false;
    this.isPrivacyPolicyAccepted = false;
    this.showPrivacyPolicySection = true;
    this.showMarketingPreferenceSection = true;
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.curExpressLoginStep = this.localExpressLoginSteps.ProvideEmailAddress;
  }

  startCounter(expireTimeInSeconds): void {
    this.countDownTimerForDisplay = "";
    this.timerSubscription = timer(1000, 1000).pipe(takeWhile((number) => this.startedVerifyCode == false && number <= expireTimeInSeconds)).subscribe((number) => {
      if (number < expireTimeInSeconds) {
        this.isResendCodeButtonDisabled = true;
        const resetTime = expireTimeInSeconds - number;
        this.countDownTimerForDisplay = "(" + resetTime.toString() + "s)";
      } else {
        this.countDownTimerForDisplay = "";
        this.isResendCodeButtonDisabled = false;
      }
    });
  }

  acceptPrivacyPolicy() {
    if (this.isPrivacyPolicyLinkClicked) {
      this.isPrivacyPolicyAccepted = !this.isPrivacyPolicyAccepted;
    }
  }

  readPrivacyPolicy(): void {
    this.isPrivacyPolicyLinkClicked = true;
    this.isPrivacyPolicyAccepted = !this.isPrivacyPolicyAccepted;
  }

  isLoginFunctionProvided(resNoAccountFunctionProvided: string): boolean {
    //If resource string Aon.Common.NoAccountFunctionProvided exist and has value.
    //It means this site want hide the login section and show some warning message on the login page.
    //Tips: if the resource string value is the key 'Aon.Common.NoAccountFunctionProvided', we consider the resource string doesn't exist.
    //User story #1435577
    if (resNoAccountFunctionProvided == "" || resNoAccountFunctionProvided == "Aon.Common.NoAccountFunctionProvided")
      return true;
    else
      return false;
  }

  private loadCustomLoginContents(): void {
    let curLanguageCode: LanguageSelectionData = JSON.parse(localStorage.getItem('SelectedLanguage'));
    this.commonService.getCommonPageWithKey(curLanguageCode.cultureCode, "/Login", "Custom-Login-Links", false)
      .subscribe(result => {
        this.customLoginLinks = result;
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any = null) {
    if (event && event?.target && event?.target?.innerWidth) {
      this.screenWidgth = event?.target?.innerWidth;
    }

    if (this.screenWidgth < 576) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
}
